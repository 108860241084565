<template>
  <div class="profile-menu">
    <!-- side bar for b2b or buyer user  -->
    <div class="d-flex justify-content-center align-items-center flex-column" v-if="opened">
      <img :src="buyerUserData.image_path" alt="" srcset="" class="company-img" />
      <h5 class="my-3" v-if="buyerUserData.company_name_en">
        {{ buyerUserData.company_name_en }}
      </h5>
      <h5 class="my-3" v-else-if="buyerUserData.company_name_ar">
        {{ buyerUserData.company_name_ar }}
      </h5>
      <h5 class="my-3" v-else>
        {{ buyerUserData.company_name_en }}
      </h5>
    </div>

    <div class="my-2 px-3" v-if="buyerUserData && buyerUserData.profile_percentage !== 100 && opened">
      <h5>{{ $t("profile.completeRate") }}</h5>
      <b-progress class="progress-rate" :class="{ 'mr-2': $i18n.locale == 'en', 'ml-2': $i18n.locale == 'ar' }"
        :value="buyerUserData.profile_percentage" max="100" show-progress animated variant="danger"></b-progress>
    </div>
    <ul v-if="!socialLogin">
      <li v-for="(link, index) in buyerLinks" :key="index" :class="{
        'd-none':
          link.name.trim() === 'Subscribe to the newsletter' &&
          buyerUserData.register_mailing_list,
      }" @click="closeSideBar">
        <router-link :to="link.to">
          <img src="@/assets/images/new-design/standing-order-sign.png" v-if="link.name == $t('profile.b2bFav')"
            class="standing-order-sign" alt="standing-order-sign" />
          <font-awesome-icon v-else :icon="`fa-solid fa-${link.iconName}`" size="1x" />
          <span v-if="opened">{{ link.name }}</span>
          <span v-if="opened">

            <span class="side-number" v-if="
              userBades &&
              userBades.orders &&
              link.name.trim() == $t('profile.ordersLists')
            ">{{ userBades.orders }}</span>
            <span class="side-number" v-if="
              userBades &&
              userBades.returns &&
              link.name.trim() === $t('profile.returnRequests')
            ">{{ userBades.returns }}</span>
            <span class="side-number" v-if="
              userBades &&
              userBades.client_messages &&
              link.name.trim() === $t('profile.supplierCorrespondence')
            ">{{ userBades.client_messages }}</span>
            <span class="side-number" v-if="
              userBades && userBades.rfqs && link.name.trim() === $t('profile.quotations')
            ">{{ userBades.rfqs }}</span>
          </span>
        </router-link>
      </li>
      <li>
        <div class="logout" @click="logout()">
          <span class="logout-sign">
            <font-awesome-icon icon="fa-solid fa-power-off" />
          </span>
          <span class="mx-2" v-if="opened">
            {{ $t("login.logout") }}
          </span>
        </div>
      </li>
    </ul>
    <ul v-else>
      <li v-for="(link, index) in SocialLinks" :key="index" :class="{
        'd-none':
          link.name.trim() === 'Subscribe to the newsletter' &&
          buyerUserData.register_mailing_list,
      }" @click="closeSideBar">
        <router-link :to="link.to">
          <img src="@/assets/images/new-design/standing-order-sign.png" v-if="link.name == $t('profile.b2bFav')"
            class="standing-order-sign" alt="standing-order-sign" />
          <font-awesome-icon :icon="`fa-solid fa-${link.iconName}`" v-else />
          <span v-if="opened">{{ link.name }}</span>
          <span v-if="opened">

            <span class="side-number" v-if="
              userBades &&
              userBades.orders &&
              link.name.trim() == $t('profile.ordersLists')
            ">{{ userBades.orders }}</span>
            <span class="side-number" v-if="
              userBades &&
              userBades.returns &&
              link.name.trim() === $t('profile.returnRequests')
            ">{{ userBades.returns }}</span>
            <span class="side-number" v-if="
              userBades &&
              userBades.client_messages &&
              link.name.trim() === $t('profile.supplierCorrespondence')
            ">{{ userBades.client_messages }}</span>
            <span class="side-number" v-if="
              userBades && userBades.rfqs && link.name.trim() === $t('profile.quotations')
            ">{{ userBades.rfqs }}</span>
          </span>
        </router-link>
      </li>
      <li>
        <div class="logout" @click="logout()">
          <span class="logout-sign">
            <font-awesome-icon icon="fa-solid fa-power-off" />
          </span>
          <span class="mx-2" v-if="opened">
            {{ $t("login.logout") }}
          </span>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      /**
       * @vuese
       *  if logged in with email and pass
       */
      buyerLinks: [
        {
          to: "/",
          name: this.$t("home.home"),
          iconName: "house",
        },
        {
          to: "/profile/categories",
          name: this.$t("profile.categories"),
          iconName: "border-all",
        },
        {
          to: "/profile/dashboard",
          name: this.$t("profile.dashboard"),
          iconName: "dashboard",
        },
        {
          to: "/profile/shopping-cart",
          name: this.$t("profile.shoppingCart"),
          iconName: "cart-shopping",
        },
        {
          to: "/profile/ordersListsB2b",
          name: this.$t("profile.ordersLists"),
          iconName: "clipboard-list",
        },
        {
          to: "/profile/ReturnRequests",
          name: this.$t("profile.returnRequests"),
          iconName: "arrow-rotate-left",
        },
        {
          to: "/profile/Wallet",
          name: this.$t("profile.wallet"),
          iconName: "wallet",
        },
        {
          to: "/profile/account-information-b2b",
          name: this.$t("profile.accountInformation"),
          iconName: "circle-info",
        },
        {
          to: "/profile/change-password",
          name: this.$t("profile.changePassword"),
          iconName: "key",
        },
        {
          to: "/profile/adress-book",
          name: this.$t("profile.addressBook"),
          iconName: "location-dot",
        },

        {
          to: "/profile/documents",
          name: this.$t("profile.documents"),
          iconName: "file",
        },
        {
          to: "/profile/subscribe-newsletter",
          name: this.$t("profile.subscribeNewsletter"),
          iconName: "newspaper",
        },

        {
          to: "/profile/favorite",
          name: this.$t("profile.favorite"),
          iconName: "heart",
        },
        {
          to: "/profile/StandingOrders",
          name: this.$t("profile.b2bFav"),
          iconName: "bag-shopping",
        },
        {
          to: "/profile/QuotationsB2b",
          name: this.$t("profile.quotations"),
          iconName: "list-ol",
        },
        {
          to: "/profile/product-reviews-b2b",
          name: this.$t("profile.productReviews"),
          iconName: "cubes",
        },
        {
          to: "/profile/SupplierCorrespondenceB2b",
          name: this.$t("profile.supplierCorrespondence"),
          iconName: "comments",
        },
        {
          to: "/profile/Notifications",
          name: this.$t("profile.Notifications"),
          iconName: "bell",
        },
        // {
        //   to: "/profile/NotificationSettings",
        //   name: this.$t("profile.NotificationSettings"),
        //   iconName: "sliders",
        // },
      ],
      /**
       * @vuese
       *  if logged in socially
       */
      SocialLinks: [
        {
          to: "/",
          name: this.$t("home.home"),
          iconName: "house",
        },
        {
          to: "/profile/categories",
          name: this.$t("profile.shop"),
          iconName: "shop",
        },
        {
          to: "/profile/dashboard",
          name: this.$t("profile.dashboard"),
          iconName: "dashboard",
        },
        {
          to: "/profile/shopping-cart",
          name: this.$t("profile.shoppingCart"),
          iconName: "cart-shopping",
        },
        {
          to: "/profile/ordersListsB2b",
          name: this.$t("profile.ordersLists"),
          iconName: "clipboard-list",
        },
        {
          to: "/profile/ReturnRequests",
          name: this.$t("profile.returnRequests"),
          iconName: "arrow-rotate-left",
        },
        {
          to: "/profile/Wallet",
          name: this.$t("profile.wallet"),
          iconName: "wallet",
        },
        {
          to: "/profile/account-information-b2b",
          name: this.$t("profile.accountInformation"),
          iconName: "circle-info",
        },
        {
          to: "/profile/adress-book",
          name: this.$t("profile.addressBook"),
          iconName: "location-dot",
        },

        {
          to: "/profile/documents",
          name: this.$t("profile.documents"),
          iconName: "file",
        },
        {
          to: "/profile/subscribe-newsletter",
          name: this.$t("profile.subscribeNewsletter"),
          iconName: "newspaper",
        },

        {
          to: "/profile/favorite",
          name: this.$t("profile.favorite"),
          iconName: "heart",
        },
        {
          to: "/profile/StandingOrders",
          name: this.$t("profile.b2bFav"),
          iconName: "bag-shopping",
        },
        {
          to: "/profile/QuotationsB2b",
          name: this.$t("profile.quotations"),
          iconName: "list-ol",
        },
        {
          to: "/profile/product-reviews-b2b",
          name: this.$t("profile.productReviews"),
          iconName: "cubes",
        },
        {
          to: "/profile/SupplierCorrespondenceB2b",
          name: this.$t("profile.supplierCorrespondence"),
          iconName: "comments",
        },
        {
          to: "/profile/Notifications",
          name: this.$t("profile.Notifications"),
          iconName: "bell",
        },
        // {
        //   to: "/profile/NotificationSettings",
        //   name: this.$t("profile.NotificationSettings"),
        //   iconName: "sliders",
        // },
      ],
    };
  },
  mounted() {
    if (!this.buyerUserData?.buyer_type_data?.free_subscription) {
      this.buyerLinks.push({
        to: "/profile/subscripePlans",
        name: this.$t("profile.subscripePlans"),
        iconName: "hand-holding-dollar",
      })
      this.SocialLinks.push({
        to: "/profile/subscripePlans",
        name: this.$t("profile.subscripePlans"),
        iconName: "hand-holding-dollar",
      })
    }
  },
  /**
   *  props
   */
  props: {
    userBades: {
      // userBades prop
      type: Object,
      required: false,
    },
    opened: {
      type: Boolean
    }
  },
  methods: {
    closeSideBar() {
      this.$emit('closeSideMenu')
    }
  }
};
</script>

<style lang="scss">
/**
      *  component style
    */
.profile-menu {
  background-color: #1f1f1f;
  color: #fff;
  font-size: 16px;

  ul {
    li {
      padding: 10px 20px;
      border-bottom: 2px solid #4d4d4d;
      font-size: 15px;

      &:first-of-type {
        border-top: 2px solid #4d4d4d;
      }

      .router-link-exact-active {
        color: $main-color;
      }

      a {
        display: inline-block;
        color: #fff;

        span {
          padding: 0 10px;
          text-transform: capitalize;
        }
      }
    }
  }
}

// style arabic
html:lang(ar) {
  .profile-menu {
    text-align: right;
  }
}

.logout {
  cursor: pointer;
}

.company-img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: fill;
}
</style>
